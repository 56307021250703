module.exports = {
  // codingpains: require('./players/codingpains.js'),
  manuelmhtr: require('./players/manuelmhtr.js'),
  ericku: require('./players/ericku.js'),
  siegfried: require('./players/siegfried.js'),
  horror: require('./players/horror.js'),
  elperron: require('./players/elperron.js'),
  yuno: require('./players/yuno.js'),
  xmontoya: require('./players/xmontoya.js'),
  margeux: require('./players/margeux.js')
};
